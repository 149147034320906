

 // ASSUME JQUERY ELEMENTS. Todo: don't assume. (String would also have length)

function animateCountWithBackground($foreground, $background, triggerElement, name) {
    /*
    * This function takes a foreground and background element
    * and creates a GSAP animation for counts.
    */

    // init controller
    var controller = new ScrollMagic.Controller();

    // Defaults
    $background.removeAttr('style');
    if (!$foreground.attr('data-number')) {
        $foreground.attr('data-number', $foreground.find('span').html());
    }
    var number = $foreground.attr('data-number');
    var comma = number.indexOf(',') > 0 ? true : false;
    var fontSize = $background.css('font-size');
    var count = {
        val: 0,
        end: number.replace(',','')
    }

    // Set initial values.
    TweenLite.set($background, {fontSize: '0'});
    $foreground.find('span').html('0');

    // Create timeline.
    var timeline = new TimelineMax();

    timeline.to($background, 2, {fontSize: fontSize})
            .to(count, 2, { val: count.end, roundProps:"val", onUpdate: function() {
                
                if (comma) {
                    // Count the number of characters.
                    // Add commas.
                    // e.g. 4,500 | 12,342,234 | 230
                    var loops = Math.ceil( count.val.toString().length / 3 );
                    var number = 
                        (loops > 2 ? count.val.toString().slice(-9,-6) + '.' : '') +
                        (loops > 1 ? count.val.toString().slice(-6,-3) + ',' : '') +
                        count.val.toString().slice(-3);
                } else {
                    var number = count.val;
                }

                $foreground.find('span').html(number);
            }, onUpdateParams: [count]}, '-=2'); 

    var stat = new ScrollMagic.Scene({triggerElement: triggerElement, duration: 0})
                        .setTween(timeline)
                        // .addIndicators({name: name})
                        .addTo(controller)
                        .reverse(false);
}


function animateCount($foreground, triggerElement, duration, name) {
    /*
    * This function takes a foreground element
    * and creates a GSAP animation for counts.
    */

    var duration =  duration ? duration : 2;
    // init controller
    var controller = new ScrollMagic.Controller();

    // Because we want to be able to reflow, and we're just tracking this data
    // in the DOM, let's store it there independently.
    if (!$foreground.attr('data-number')) {
        $foreground.attr('data-number', $foreground.children('span').html());
    }

    var number = $foreground.attr('data-number');

    var comma = number.indexOf(',') > 0 ? true : false;
    var count = {
        val: 0,
        end: number.replace(/,/g,'')
    }
    // Set initial values.
    $foreground.children('span').html('0');

    // Create timeline.
    var timeline = new TimelineMax();

    timeline.to(count, duration, { val: count.end, roundProps:"val", onUpdate: function() {               
                if (comma) {
                    // Count the number of characters.
                    // Add commas.
                    // e.g. 4,500 | 12,342,234 | 230
                    var loops = Math.ceil( count.val.toString().length / 3 );
                    var number = 
                        (loops > 2 ? count.val.toString().slice(-9,-6) + ',' : '') +
                        (loops > 1 ? count.val.toString().slice(-6,-3) + ',' : '') +
                        count.val.toString().slice(-3);
                } else {
                    var number = count.val;
                }

                $foreground.find('span').html(number);
            }, onUpdateParams: [count]}, '-=2'); 

    var stat = new ScrollMagic.Scene({triggerElement: triggerElement, duration: 0})
                        .setTween(timeline)
                        // .addIndicators({name: name})
                        .addTo(controller)
                        .reverse(false);
}

function animateHeight($element, triggerElement, name, defaultHeight, position) {

    // init controller
    var controller = new ScrollMagic.Controller();

    position ? position = position : "+=0";

    // Create timeline.
    var timeline = new TimelineMax();

    // Defaults
    $element.removeAttr('style');
    var height = $element.outerHeight();
    
    // save space
    $element.parents('.height-wrap').css('min-height', height);

    $element.css('overflow', 'hidden'); // Just in case.
    $element.css('flex-grow', '0'); // Makes it graceful.
    defaultHeight === 0 ? $element.height('0') : $element.height('10px');

    // if ($element.length > 1) {
    //     // We have more than one element, let's stagger!
    //     timeline.staggerFromTo($element, 2, { height: defaultHeight === 0 ? '0' : '10px' }, { height: height }, 1, position);
    // } else {
        timeline.fromTo($element, 1, { height: defaultHeight === 0 ? '0' : '10px' }, { height: height }, position);
    // }

    var stat = new ScrollMagic.Scene({triggerElement: triggerElement, duration: 0})
                        .setTween(timeline)
                        // .addIndicators({name: name})
                        .addTo(controller)
                        .reverse(false);
}


function animateHorizontalSwipe($element, triggerElement, name, duration) {

    // init controller
    var controller = new ScrollMagic.Controller();

    duration ? duration = duration : duration = 2;

    // Create timeline.
    var timeline = new TimelineMax();

    // Get the size of the underlying asset,
    // which we can use to set the size of both.
    // Ensures that they line up.
    var $swipeBackground = $element.find('.swipe__background');
    var bgWidth = $swipeBackground.outerWidth();
    var bgHeight = $swipeBackground.outerHeight();
    $mask = $element.find('.swipe__mask');
    $mask.removeAttr('style');

    // Set the top layer to the same size.
    $mask.find('img').height(bgHeight);
    $mask.find('img').width(bgWidth);

    $mask.width('0');
    
    timeline.to($mask, duration, { width: bgWidth });

    var stat = new ScrollMagic.Scene({triggerElement: triggerElement, duration: 0})
                        .setTween(timeline)
                        // .addIndicators({name: name})
                        .addTo(controller)
                        .reverse(false);
}

function animateHorizontalSlide($element, triggerElement, delay, name, position) {
    // init controller
    var controller = new ScrollMagic.Controller();

    // Create timeline.
    var timeline = new TimelineMax();
    var width = $element.outerWidth();
    // Set element width even it is hidden;
    $element.removeAttr('style');
    $element.width(width)
    var from = { left: -width, opacity:0 };
    var to = {left: 0, delay:delay, opacity:1, ease: Power2.easeOut};
    timeline.fromTo($element, 1, from, to, "+=1")

    var stat = new ScrollMagic.Scene({ triggerElement: triggerElement, duration: 0 })
        .setTween(timeline)
        // .addIndicators({ name: name })
        .addTo(controller)
        .reverse(false);
}

function animateGrow($element, triggerElement, name, position) {
    // init controller
    var controller = new ScrollMagic.Controller();

    // Create timeline.
    var timeline = new TimelineMax();

    $element.removeAttr('style');

    var from = { transform: 'scale(0.6, 0.6)'};
    var to = { transform: 'scale(1, 1)' };

    timeline.fromTo($element, 2, from, to, position);

    var stat = new ScrollMagic.Scene({ triggerElement: triggerElement, duration: 0 })
            .setTween(timeline)
            // .addIndicators({ name: name })
            .addTo(controller)
            .reverse(false);
}

function animateSwipe($element, triggerElement, name, position) {
    // init controller
    var controller = new ScrollMagic.Controller();

    // Create timeline.
    var timeline = new TimelineMax();
    $element.removeAttr('style');

    var from = { width:'100%' };
    var to = { width: 0 };

    timeline.fromTo($element, 1, from, to, position);

    var stat = new ScrollMagic.Scene({ triggerElement: triggerElement, duration: 0 })
            .setTween(timeline)
            // .addIndicators({ name: name })
            .addTo(controller)
            .reverse(false);
}

function animateRadialSwipe($element, triggerElement, name) {
    var RAD  = Math.PI / 180;
    var PI_2 = Math.PI / 2;

    var clipPath = document.querySelector("#" + name + "__arcPath");

    var clipCircle = document.querySelector('#' + name + '__clipCircle');
    var image = document.querySelector('#' + name + '__image');


    // Find the original img.
    var defaultImg = $element.find('img.chart');
    defaultImg.show();
    var svg = $element.find('svg');
    var height = defaultImg.outerHeight();
    var width = defaultImg.outerWidth();
    defaultImg.hide(); // hide the img.

    // Set the SVG to the height that the image would occupy.
    image.setAttribute("width", width);
    image.setAttribute("height", height);
    svg.css("width", width);
    svg.css("height", height);

    // And the cropCircle
    clipCircle.setAttribute("cx", width/2);
    clipCircle.setAttribute("cy", height/2);
    clipCircle.setAttribute("r", height/2);


    var arc = {  
        start: 0,
        end: 0,
        cx: (width / 2),
        cy: (height / 2),
        r: (width / 2)
    };

    updatePath();

    function updatePath() {
        clipPath.setAttribute("d", getPath(arc.cx, arc.cy, arc.r, arc.start, arc.end)); 
        // $element.css("clip-path", getPath(arc.cx, arc.cy, arc.r, arc.start, arc.end)); 
    }

    function getPath(cx, cy, r, a1, a2) {
    
        var delta = a2 - a1;
        
        if (delta === 360) { 
            return "M " + (cx - r) + " " + cy + " a " + r + " " + r + " 0 1 0 " + r * 2 + " 0 a " + r + " " + r + " 0 1 0 " + -r * 2 + " 0z"; 
        }
        
        var largeArc = delta > 180 ? 1 : 0;
            
        a1 = a1 * RAD - PI_2;
        a2 = a2 * RAD - PI_2;

        var x1 = cx + r * Math.cos(a2);   
        var y1 = cy + r * Math.sin(a2);

        var x2 = cx + r * Math.cos(a1); 
        var y2 = cy + r * Math.sin(a1);
            
        return "M " + x1 + " " + y1 + " A " + r + " " + r + " 0 " + largeArc + " 0 " + x2 + " " + y2 + " L " + cx + " " + cy + "z";
    }

    // init controller
    var controller = new ScrollMagic.Controller();

    // Create timeline.
    var timeline = new TimelineMax();

    timeline.to(arc, 2, {
        end: 360,
        ease: Linear.easeNone,
        onUpdate: updatePath,
        // repeat: -1, 
        repeatDelay: 0.25,
    });

    //
    var stat = new ScrollMagic.Scene({triggerElement: triggerElement, duration: 0})
        .setTween(timeline)
        // .addIndicators({name: name})
        .addTo(controller)
        .reverse(false);

}


function animateFade($element, triggerElement, delay, name, position) {
    // E.g. stat 09, 11, 

    position ? position = position : "+=0";
    
    // init controller
    var controller = new ScrollMagic.Controller();

    // Create timeline.
    var timeline = new TimelineMax();

    $element.removeAttr('style');

    var duration = 1;
    var from = { opacity: 0, ease: Linear.ease };
    var to = { opacity: 1, delay: delay};

    // if ($element.length > 1) {
    //     // Array of elements
    //     timeline.staggerFromTo($element, duration, from, to, 0.5, position);
    // } else {
        timeline.fromTo($element, duration, from, to, position);
    // }

    var stat = new ScrollMagic.Scene({ triggerElement: triggerElement, duration: 0 })
        .setTween(timeline)
        // .addIndicators({ name: name })
        .addTo(controller)
        .reverse(false);
}

function animateFadeSet($element, triggerElement, delay, name) {
    // E.g. stat 09, 11, 

    // init controller
    var controller = new ScrollMagic.Controller();

    // Create timeline.
    var timeline = new TimelineMax();

    $element.removeAttr('style');

    var duration = 2;
    var from = { opacity: 0, ease: Linear.ease };
    var to = { opacity: 1, delay: delay};

    if ($element.length > 1) {
        // Array of elements
        timeline.fromTo($element, duration, from, to);
    } else {
        timeline.fromTo($element, duration, from, to);
    }
    var stat = new ScrollMagic.Scene({ triggerElement: triggerElement, duration: 0 })
        .setTween(timeline)
        // .addIndicators({ name: name })
        .addTo(controller)
        .reverse(false);
}

function animateTextSizeWithFade($element, triggerElement) {
    // init controller
    var controller = new ScrollMagic.Controller();

    // Create timeline.
    var timeline = new TimelineMax();
    $element.removeAttr('style');

    var fontSize = $element.css('font-size');

    var from = { fontSize: 0, opacity: 0 };
    var to = { fontSize: fontSize, opacity: 1};

    timeline.fromTo($element, 2, from, to);

    var stat = new ScrollMagic.Scene({triggerElement: triggerElement, duration: 0})
                        .setTween(timeline)
                        // .addIndicators({name: name})
                        .addTo(controller)
                        .reverse(false);
}



function aroundTheWorld($element, triggerElement, name) {
    var RAD  = Math.PI / 180;
    var PI_2 = Math.PI / 2;

    var clipPath = document.querySelector("#" + name + "__arcPath");

    var clipCircle = document.querySelector('#' + name + '__clipCircle');
    var image = document.querySelector('#' + name + '__image');


    // Find the original img.
    var defaultImg = $element.find('img.chart');
    var svg = $element.find('svg');
    var height = defaultImg.outerHeight();
    var width = defaultImg.outerWidth();
    defaultImg.hide(); // hide the img.

    // Set the SVG to the height that the image would occupy.
    image.setAttribute("width", width);
    image.setAttribute("height", height);
    svg.css("width", width);
    svg.css("height", height);

    // And the cropCircle
    clipCircle.setAttribute("cx", width/2);
    clipCircle.setAttribute("cy", height/2);
    clipCircle.setAttribute("r", height/2);


    var arc = {  
        start: 360,
        end: 190, 
        cx: (width / 2),
        cy: (height / 2),
        r: (height / 2)
    };

    updatePath();

    function updatePath() {
        clipPath.setAttribute("d", getPath(arc.cx, arc.cy, arc.r, arc.end, arc.start));
    }

    function getPath(cx, cy, r, a1, a2) {
    
        var delta = a2 - a1;
        
        if (delta === 360) { 
            return "M " + (cx - r) + " " + cy + " a " + r + " " + r + " 0 1 0 " + r * 2 + " 0 a " + r + " " + r + " 0 1 0 " + -r * 2 + " 0z"; 
        }
        
        var largeArc = delta > 180 ? 1 : 0;
            
        a1 = a1 * RAD - PI_2;
        a2 = a2 * RAD - PI_2;

        var x1 = cx + r * Math.cos(a2);   
        var y1 = cy + r * Math.sin(a2);

        var x2 = cx + r * Math.cos(a1); 
        var y2 = cy + r * Math.sin(a1);
            
        return "M " + x1 + " " + y1 + " A " + r + " " + r + " 0 " + largeArc + " 0 " + x2 + " " + y2 + " L " + cx + " " + cy + "z";
    }

    // init controller
    var controller = new ScrollMagic.Controller();

    // Create timeline.
    var timeline = new TimelineMax();

    timeline.to(arc, 2, {
        end: 0,
        ease: Linear.easeNone,
        onUpdate: updatePath,
    });

    // Rotate plane.
    $plane = $element.find('.stat19__plane img');
    TweenMax.set($plane, {rotation: 200, transformOrigin: "center"});
    timeline.to($plane, 2, {
        rotation: 0,
        transformOrigin: "center",
        ease: Linear.easeNone,
    }, '');

    //
    var stat = new ScrollMagic.Scene({triggerElement: triggerElement, duration: 0})
        .setTween(timeline)
        // .addIndicators({name: name})
        .addTo(controller)
        .reverse(false);

}



// Resets
function resetAnimateRadialSwipe() {

}


// jQuery plugins

(function($,sr){

    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
        var timeout;
  
        return function debounced () {
            var obj = this, args = arguments;
            function delayed () {
                if (!execAsap)
                    func.apply(obj, args);
                timeout = null;
            };
  
            if (timeout)
                clearTimeout(timeout);
            else if (execAsap)
                func.apply(obj, args);
  
            timeout = setTimeout(delayed, threshold || 750);
        };
    }
    // smartresize 
    jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
  
  })(jQuery,'smartresize');
